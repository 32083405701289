@font-face {
  font-family: "Varela Round";
  font-style: normal;
  font-weight: 400;
  src: url("../../../libs/clarity-core/src/assets/fonts/varela-round/VarelaRound-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 400;
  src: url("../../../libs/clarity-core/src/assets/fonts/source-sans-pro/SourceSansPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Source Sans Pro It";
  font-weight: 400;
  src: url("../../../libs/clarity-core/src/assets/fonts/source-sans-pro/SourceSansPro-It.otf") format("opentype");
}

@font-face {
  font-family: "Source Sans Pro Semibold";
  font-weight: 600;
  src: url("../../../libs/clarity-core/src/assets/fonts/source-sans-pro/SourceSansPro-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "Source Sans Pro Bold";
  font-weight: 700;
  src: url("../../../libs/clarity-core/src/assets/fonts/source-sans-pro/SourceSansPro-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Patrick Hand";
  font-weight: 400;
  src: url("../../../libs/clarity-core/src/assets/fonts/patrick-hand/PatrickHand-Regular.ttf") format("truetype");
}

// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "swiper/scss";
@import "swiper/scss/virtual";
@import "swiper/scss/pagination";
@import "swiper/scss/effect-fade";

@import "./loading";
@import "../../../libs/clarity-core/src/app/app";
